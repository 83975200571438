import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

class EHealthFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [
        {
          slug: "terms",
          title: " ",
        },
      ],
      questions: [
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "general-terms",
          question: "terms-general-terms-title",
          answer: "terms-general-terms-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "privacy-policy",
          question: "terms-privacy-policy-title",
          answer: "terms-privacy-policy-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "user-contributed-content-and-its-uses",
          question: "terms-user-contributed-data-title",
          answer: "terms-user-contributed-data-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "disclaimer",
          question: "terms-disclaimer-title",
          answer: "terms-disclaimer-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "copyright-and-intellectual-property-right",
          question: "terms-copyright-title",
          answer: "terms-copyright-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "terms-for-specific-services",
          question: "terms-specific-services-title",
          answer: "terms-specific-services-details",
        },
        {
          isOpen: false,
          sectionSlug: "terms",
          slug: "notice-to-caregiver",
          question: "terms-notice-caregiver-title",
          answer: "terms-notice-caregiver-details",
        },
      ],
    };
  }
  toggleQuestion(sectionSlug, slug) {
    const newQuestions = [...this.state.questions];
    const questionIndex = newQuestions.findIndex(
      (question) =>
        question.slug === slug && question.sectionSlug === sectionSlug
    );
    if (questionIndex === -1) return;
    newQuestions[questionIndex].isOpen = !newQuestions[questionIndex].isOpen;
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleSectionQuestions(slug) {
    const newQuestions = [...this.state.questions];
    const sectionQuestions = newQuestions.filter(
      (question) => question.sectionSlug === slug
    );
    const sectionOpen = sectionQuestions.some((question) => !question.isOpen);
    sectionQuestions.forEach((question) => {
      question.isOpen = sectionOpen;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleAll() {
    const newQuestions = [...this.state.questions];
    const open = newQuestions.some((question) => !question.isOpen);
    newQuestions.forEach((question) => {
      question.isOpen = open;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  scrollToQuestion() {
    const navHeight = window.innerWidth > 1260 ? 80 : 64;
    const offsetTop = navHeight + 20;
    let anchor =
      this.getParameterByName("section") ?? window.location.href.split("#")[1];
    anchor = anchor?.split("?")[0];
    if (!anchor) return;

    const type = anchor?.includes("_") ? "question" : "section";
    const sectionSlug = type === "question" ? anchor.split("_")[0] : anchor;
    if (!sectionSlug) return;

    if (type === "question") {
      const questionSlug = anchor.split("_")[1]?.split("?")[0];
      console.log({ questionSlug });
      const questionElement = document.getElementById(
        `faq-question-container-${sectionSlug}-${questionSlug}`
      );
      if (!questionElement) return;

      setTimeout(() => {
        this.toggleQuestion(sectionSlug, questionSlug);
        window.scrollTo({
          top: questionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }

    if (type === "section") {
      this.toggleSectionQuestions(sectionSlug);
      const sectionElement = document.getElementById(
        `faq-section-${sectionSlug}`
      );
      if (!sectionElement) return;
      setTimeout(() => {
        window.scrollTo({
          top: sectionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    this.scrollToQuestion();
  }

  render() {
    const { locale, t } = this.props;
    let {
      eHealthSite,
      registerLink,
      proRegisterLink,
      InfoCollectLink,
      PrivacyPolicyLink,
    } = this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/index.html";
      proRegisterLink =
        "https://www.ehealth.gov.hk/en/healthcare-provider-and-professional/how-to-register/index.html.";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=en";
      InfoCollectLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/support/hcr-personal-information-collection-statement.html";
      PrivacyPolicyLink =
        "https://www.ehealth.gov.hk/en/privacy-policy/index.html";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/index.html";
      proRegisterLink =
        "https://www.ehealth.gov.hk/sc/healthcare-provider-and-professional/how-to-register/index.html.";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=sc";
      InfoCollectLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/support/hcr-personal-information-collection-statement.html";
      PrivacyPolicyLink =
        "https://www.ehealth.gov.hk/sc/privacy-policy/index.html";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/index.html";
      proRegisterLink =
        "https://www.ehealth.gov.hk/tc/healthcare-provider-and-professional/how-to-register/index.html.";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=tc";
      InfoCollectLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/support/hcr-personal-information-collection-statement.html";
      PrivacyPolicyLink =
        "https://www.ehealth.gov.hk/tc/privacy-policy/index.html";
    }

    const answers = {
      "terms-general-terms-details": (
        <>
          <h4>{t("terms-general-terms-section-01")}</h4>
          <ol>
            <li>{t("terms-general-terms-section-01-1")}</li>
            <li>{t("terms-general-terms-section-01-2")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-02")}</h4>
          <ol start="3">
            <li>{t("terms-general-terms-section-02-1")}</li>
            <li>{t("terms-general-terms-section-02-2")}</li>
            <li>
              {t("terms-general-terms-section-02-3")}
              <br />
              <a href={registerLink} target="blank">
                {registerLink}
              </a>
              <br />
              <a href={proRegisterLink} target="blank">
                {proRegisterLink}
              </a>
            </li>
            <li>{t("terms-general-terms-section-02-4")}</li>
            <li>{t("terms-general-terms-section-02-5")}</li>
            <li>{t("terms-general-terms-section-02-6")}</li>
            <li>{t("terms-general-terms-section-02-7")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-03")}</h4>
          <ol start="10">
            <li>{t("terms-general-terms-section-03-1")}</li>
            <li>{t("terms-general-terms-section-03-2")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-04")}</h4>
          <ol start="12">
            <li>{t("terms-general-terms-section-04-1")}</li>
            <li>{t("terms-general-terms-section-04-2")}</li>
            <li>{t("terms-general-terms-section-04-3")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-05")}</h4>
          <ol start="15">
            <li>{t("terms-general-terms-section-05-1")}</li>
            <li>{t("terms-general-terms-section-05-2")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-elife-acc")}</h4>
          <ol start="17">
            <li>{t("terms-general-terms-section-elife-acc-detail-1")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-06")}</h4>
          <ol start="18">
            <li>{t("terms-general-terms-section-06-1")}</li>
            <li>{t("terms-general-terms-section-06-2")}</li>
            <li>{t("terms-general-terms-section-06-3")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-07")}</h4>
          <ol start="21">
            <li>{t("terms-general-terms-section-07-1")}</li>
            <li>{t("terms-general-terms-section-07-2")}</li>
            <li>{t("terms-general-terms-section-07-3")}</li>
            <li>{t("terms-general-terms-section-07-4")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-health-coins")}</h4>
          <ol start="25">
            <li>{t("terms-general-terms-section-health-coins-1")}</li>
            <li>{t("terms-general-terms-section-health-coins-2")}</li>
            <li>{t("terms-general-terms-section-health-coins-3")}</li>
            <li>{t("terms-general-terms-section-health-coins-4")}</li>
            <li>{t("terms-general-terms-section-health-coins-5")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-08")}</h4>
          <ol start="30">
            <li>{t("terms-general-terms-section-08-1")}</li>
            <li>{t("terms-general-terms-section-08-2")}</li>
            <li>{t("terms-general-terms-section-08-3")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-09")}</h4>
          <ol start="33">
            <li>{t("terms-general-terms-section-09-1")}</li>
            <li>{t("terms-general-terms-section-09-2")}</li>
            <li>{t("terms-general-terms-section-09-3")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-10")}</h4>
          <ol start="36">
            <li>{t("terms-general-terms-section-10-1")}</li>
          </ol>

          <h4>{t("terms-general-terms-section-11")}</h4>
          <ol start="37">
            <li>{t("terms-general-terms-section-11-1")}</li>
          </ol>
          <h4>{t("terms-general-terms-section-12")}</h4>
          <ol start="38">
            <li>{t("terms-general-terms-section-12-1")}</li>
            <li>{t("terms-general-terms-section-12-2")}</li>
          </ol>
        </>
      ),

      "terms-privacy-policy-details": (
        <>
          <h4>{t("terms-privacy-policy-details-section-01")}</h4>
          <ol start="40">
            <li>{t("terms-privacy-policy-details-section-01-1")}</li>
            <li>{t("terms-privacy-policy-details-section-01-2")}</li>
            <li>
              {t("terms-privacy-policy-details-section-01-3")}
              <a href={PrivacyPolicyLink} target="blank">
                {PrivacyPolicyLink}
              </a>
            </li>
            <li>{t("terms-privacy-policy-details-section-01-4")}</li>
            <li>{t("terms-privacy-policy-details-section-01-5")}</li>
            <li>{t("terms-privacy-policy-details-section-01-6")}</li>
            <li>{t("terms-privacy-policy-details-section-01-7")}</li>
            <li>{t("terms-privacy-policy-details-section-01-8")}</li>
            <li>{t("terms-privacy-policy-details-section-01-9")}</li>
            <li>{t("terms-privacy-policy-details-section-01-10")}</li>
            <li>{t("terms-privacy-policy-details-section-01-11")}</li>
            <li>{t("terms-privacy-policy-details-section-01-12")}</li>
            <li>{t("terms-privacy-policy-details-section-01-13")}</li>
            <li>{t("terms-privacy-policy-details-section-01-14")}</li>
            <li>{t("terms-privacy-policy-details-section-01-15")}</li>
          </ol>

          <h4>{t("terms-privacy-policy-details-section-02")}</h4>
          <ol start="55">
            <li>{t("terms-privacy-policy-details-section-02-1")}</li>
            <li>{t("terms-privacy-policy-details-section-02-2")}</li>
            <li>{t("terms-privacy-policy-details-section-02-3")}</li>
          </ol>

          <h4>{t("terms-privacy-policy-details-section-03")}</h4>
          <ol start="58">
            <li>{t("terms-privacy-policy-details-section-03-1")}</li>
          </ol>

          <h4>{t("terms-privacy-policy-details-section-04")}</h4>
          <ol start="59">
            <li>
              {t("terms-privacy-policy-details-section-04-1")}
              <a href={InfoCollectLink} target="blank">
                {InfoCollectLink}
              </a>
            </li>
          </ol>
        </>
      ),
      "terms-user-contributed-data-details": (
        <>
          <ol start="60">
            <li>{t("terms-user-contributed-data-details-1")}</li>
            <li>{t("terms-user-contributed-data-details-2")}</li>
            <li>{t("terms-user-contributed-data-details-3")}</li>
            <li>{t("terms-user-contributed-data-details-4")}</li>
            <li>{t("terms-user-contributed-data-details-5")}</li>
            <li>{t("terms-user-contributed-data-details-6")}</li>
          </ol>
        </>
      ),
      "terms-disclaimer-details": (
        <>
          <h4>{t("terms-disclaimer-details-section-01")}</h4>
          <ol start="66">
            <li>{t("terms-disclaimer-details-section-01-1")}</li>
            <li>{t("terms-disclaimer-details-section-01-2")}</li>
            <li>{t("terms-disclaimer-details-section-01-3")}</li>
            <li>{t("terms-disclaimer-details-section-01-4")}</li>
            <li>{t("terms-disclaimer-details-section-01-5")}</li>
            <li>{t("terms-disclaimer-details-section-01-6")}</li>
            <li>{t("terms-disclaimer-details-section-01-7")}</li>
            <li>{t("terms-disclaimer-details-section-01-8")}</li>
            <li>{t("terms-disclaimer-details-section-01-9")}</li>
          </ol>

          <h4>{t("terms-disclaimer-details-section-02")}</h4>
          <ol start="75">
            <li>{t("terms-disclaimer-details-section-02-1")}</li>
            <li>{t("terms-disclaimer-details-section-02-2")}</li>
            <li>{t("terms-disclaimer-details-section-02-3")}</li>
            <li>{t("terms-disclaimer-details-section-02-4")}</li>
            <li>{t("terms-disclaimer-details-section-02-5")}</li>
            <li>{t("terms-disclaimer-details-section-02-6")}</li>
            <li>{t("terms-disclaimer-details-section-02-7")}</li>
            <li>{t("terms-disclaimer-details-section-02-8")}</li>
          </ol>
        </>
      ),
      "terms-copyright-details": (
        <>
          <h4>{t("terms-copyright-details-section-01")}</h4>
          <ol start="83">
            <li>{t("terms-copyright-details-section-01-1")}</li>
            <li>{t("terms-copyright-details-section-01-2")}</li>
            <li>{t("terms-copyright-details-section-01-3")}</li>
            <li>{t("terms-copyright-details-section-01-4")}</li>
            <li>{t("terms-copyright-details-section-01-5")}</li>
          </ol>
        </>
      ),
      "terms-specific-services-details": (
        <>
          <h4>{t("terms-specific-services-details-section-01")}</h4>
          <h5>{t("terms-specific-services-underline-caregiver")}</h5>
          <ol start="88">
            <li>{t("terms-specific-services-details-section-01-1")}</li>
            <li>{t("terms-specific-services-details-section-01-2")}</li>
            <li>{t("terms-specific-services-details-section-01-3")}</li>
            <li>{t("terms-specific-services-details-section-01-4")}</li>
            <li>{t("terms-specific-services-details-section-01-5")}</li>
            <li>{t("terms-specific-services-details-section-01-6")}</li>
            <li>{t("terms-specific-services-details-section-01-7")}</li>
          </ol>
          <h5>{t("terms-specific-services-underline-family-member")}</h5>
          <ol start="95">
            <li>{t("terms-specific-services-details-section-01-8")}</li>
            <li>{t("terms-specific-services-details-section-01-9")}</li>
            <li>{t("terms-specific-services-details-section-01-10")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-02")}</h4>
          <ol start="98">
            <li>{t("terms-specific-services-details-section-02-1")}</li>
            <li>{t("terms-specific-services-details-section-02-2")}</li>
            <li>{t("terms-specific-services-details-section-02-3")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-03")}</h4>
          <ol start="101">
            <li>{t("terms-specific-services-details-section-03-1")}</li>
            <li>{t("terms-specific-services-details-section-03-2")}</li>
            <li>{t("terms-specific-services-details-section-03-3")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-04")}</h4>
          <ol start="104">
            <li>{t("terms-specific-services-details-section-04-1")}</li>
            <li>{t("terms-specific-services-details-section-04-2")}</li>
            <li>{t("terms-specific-services-details-section-04-3")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-05")}</h4>
          <ol start="107">
            <li>{t("terms-specific-services-details-section-05-1")}</li>
            <li>{t("terms-specific-services-details-section-05-2")}</li>
            <li>{t("terms-specific-services-details-section-05-3")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-06")}</h4>
          <ol start="110">
            <li>{t("terms-specific-services-details-section-06-1")}</li>
            <li>{t("terms-specific-services-details-section-06-2")}</li>
            <li>{t("terms-specific-services-details-section-06-3")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-07")}</h4>
          <ol start="113">
            <li>{t("terms-specific-services-details-section-07-1")}</li>
            <li>{t("terms-specific-services-details-section-07-2")}</li>
            <li>{t("terms-specific-services-details-section-07-3")}</li>
            <li>{t("terms-specific-services-details-section-07-4")}</li>
          </ol>

          <h4>{t("terms-specific-services-details-section-08")}</h4>
          <ol start="117">
            <li>{t("terms-specific-services-details-section-08-1")}</li>
            <li>{t("terms-specific-services-details-section-08-2")}</li>
            <li>{t("terms-specific-services-details-section-08-3")}</li>
            <li>{t("terms-specific-services-details-section-08-4")}</li>
            <li>{t("terms-specific-services-details-section-08-5")}</li>
            <li>{t("terms-specific-services-details-section-08-6")}</li>
            <li>{t("terms-specific-services-details-section-08-7")}</li>
          </ol>
        </>
      ),
      "terms-notice-caregiver-details": (
        <>
          <h4>{t("terms-notice-caregiver-details-section-01")}</h4>
          <ol start="1">
            <li>{t("terms-notice-caregiver-details-section-01-1")}</li>
            <li>{t("terms-notice-caregiver-details-section-01-2")}</li>
            <li>{t("terms-notice-caregiver-details-section-01-3")}</li>
            <li>{t("terms-notice-caregiver-details-section-01-4")}</li>
            <li>{t("terms-notice-caregiver-details-section-01-5")}</li>
            <li>{t("terms-notice-caregiver-details-section-01-6")}</li>
          </ol>
        </>
      ),
    };

    return (
      <div
        className={`terms-of-use-container page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/terms-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/terms-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container faq-container">
          <div className="row">
            {this.state.sections.map((section) => {
              return (
                <div id={`faq-section-${section.slug}`} className="faq-section">
                  <div className="faq-title">
                    <h3>{t(section.title)}</h3>
                    <span
                      className="faq-expand"
                      onClick={() => this.toggleSectionQuestions(section.slug)}
                    >
                      {this.state.questions.some(
                        (question) =>
                          question.sectionSlug === section.slug &&
                          !question.isOpen
                      )
                        ? t("Expand all section")
                        : t("Collapse all section")}
                    </span>
                  </div>
                  {this.state.questions
                    .filter((question) => question.sectionSlug === section.slug)
                    .map((question) => {
                      return (
                        <div
                          id={`faq-question-container-${question.sectionSlug}-${question.slug}`}
                          className="faq-question-container"
                        >
                          <div
                            className="faq-question"
                            onClick={() =>
                              this.toggleQuestion(
                                question.sectionSlug,
                                question.slug
                              )
                            }
                          >
                            {t(question.question)}
                            <img
                              className="faq-expand-icon"
                              src={load(
                                `${
                                  question.isOpen
                                    ? "./resources/images/collapse-button.png"
                                    : "./resources/images/collapse-button-open.png"
                                }`
                              )}
                            />
                          </div>
                          {question.isOpen && (
                            <div className="faq-answer">
                              {answers[question.answer] || t(question.answer)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let str = window.location.href;
  str = str.split("?")[0].split("#").reverse()[0];
  if (str == "faq-section5") {
    console.log(str);
    var appointment_faq = document
      .getElementById("faq-section5-03")
      .getElementsByClassName("faq-question")[0];
    appointment_faq.click();
    console.log(appointment_faq);
  }
});
export default PageHOC(EHealthFaq);

import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class CaregiverRedemptionPage extends Component {
  static defaultProps = {};
  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  render() {
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    const { locale, t } = this.props;

    return (
      <div
        className={` page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="hero-banner">
          <img
            className="desktop-show"
            src={load(
              `./resources/images/${locale}/under16-gift-banner-desktop.jpg`
            )}
          />
          <img
            className="mobile-show"
            src={load(
              `./resources/images/${locale}/under16-gift-banner-mobile.jpg`
            )}
          />
        </div>

        <div className="container-fluid under16-earn-coins-title">
          <div className="container">
            <h3 className="">
              <img
                className="under16-icon"
                src={load(`./resources/images/under16-earn-coin-icon.png`)}
              />
              {t("Earn health coins to redeem gifts!")}
            </h3>
          </div>
        </div>

        <div className="container-fluid under16-earn-coins-container">
          <div className="container">
            <div className="col-sm-6 under16-earn-coins-col">
              <div className="under16-earn-coins-col-content">
                <img
                  className="mission-graphic"
                  src={load(`./resources/images/under16-mission1-graphic.png`)}
                />
                <span className="mission-tag">{t("Mission 1")}</span>
                <span className="mission-detail">
                  {t("mission detail mission 1")}
                </span>
                <span className="mission-date">
                  {t("mission date mission 1")}
                </span>
                <a
                  href="/caregiver#one-stop-registration"
                  className="mission-btn"
                >
                  {t("mission btn mission 1")}
                </a>
              </div>
            </div>

            <div className="col-sm-6 under16-earn-coins-col">
              <div className="under16-earn-coins-col-content">
                <img
                  className="mission-graphic"
                  src={load(`./resources/images/under16-mission2-graphic.png`)}
                />
                <span className="mission-tag">{t("Mission 2")}</span>
                <span className="mission-detail">
                  {t("mission detail mission 2")}
                </span>
                <span className="mission-date">
                  {t("mission date mission 2")}
                </span>
                <a href="/caregiver#add-family-member" className="mission-btn">
                  {t("mission btn mission 2")}
                </a>
              </div>
            </div>

            <div className="col-sm-12 about-redemption-btn-row">
              <img
                className="under16-mission-redemption-icon"
                src={load(
                  `./resources/images/under16-mission-redemption-btn.png`
                )}
              />
              <a href="/elife-redemption" className="mission-btn">
                {t("mission btn below")}
              </a>
            </div>

            <div className="col-sm-12 about-redemption-btn-row redemption-info-pack-btn-row">
              <img
                className="under16-mission-redemption-icon"
                src={load(`./resources/images/under16-under16-pack-btn.png`)}
              />
              <a
                href="https://www.ehealth.gov.hk/rl/mama-pack-registration-for-minor"
                target="_block"
                className="mission-btn download-btn"
              >
                {t("download Information Pack for Minor Registration btn")}
              </a>
            </div>
          </div>
        </div>

        <div className="container-fluid birth-kit-container">
          <div className="container">
            <div className="birth-kit-content">
              <img
                className="birth-kit-icon"
                src={load(`./resources/images/under16-birth-kit-icon.png`)}
              />
              <h3 className="">{t("Birth kit title")}</h3>
              <img
                className="main-graphic"
                src={load(`./resources/images/under16-birth-kit-graphic.png`)}
              />
              <div className="birth-kit-gift-redemption-intro">
                {t("Birth kit intro")}
              </div>
              <span class="mission-date">{t("Birth kit date")}</span>
              <span class="mission-remarks">{t("Birth kit remarks")}</span>
            </div>
            <div className="col-sm-12 about-redemption-btn-row redemption-info-pack-btn-row">
              <img
                className="under16-mission-redemption-icon"
                src={load(`./resources/images/under16-under1-pack-btn.png`)}
              />
              <a
                href="https://www.ehealth.gov.hk/rl/mama-pack-registration-for-newborn"
                target="_block"
                className="mission-btn download-btn"
              >
                {t("download Information Pack for Newborn Registration btn")}
              </a>
            </div>
          </div>
        </div>

        <div className="container-fluid disclaimer-container">
          <div className="container">
            {t("under 16 disclaimer title")}
            <br />
            <ol>
              <li>{t("under 16 disclaimer 1")}</li>
              <li>{t("under 16 disclaimer 2")}</li>
              <li>{t("under 16 disclaimer 3")}</li>
              <li>{t("under 16 disclaimer 4")}</li>
              <li>{t("under 16 disclaimer 5")}</li>
              <li>{t("under 16 disclaimer 6")}</li>
              <li>{t("under 16 disclaimer 7")}</li>
            </ol>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(CaregiverRedemptionPage);
